import { User } from "@telespot/sdk";
import { IAssistant, IMessage } from "../../state";

export enum MessageRole {
  USER = "user",
  SYSTEM = "system",
  ASSISTANT = "assistant",
}
export class ChatMapper {
  public static toAPIMessage(
    newMessage: any,
    assistant: IAssistant,
    oldMessages: IMessage[] | any[]
  ) {
    const messages = [];
    const systemMessage = {
      role: MessageRole.SYSTEM,
      content: assistant.personalityPrompt,
    };

    const userMessage = {
      role: MessageRole.USER,
      content: newMessage.content,
    };

    const previousMessages = oldMessages
      .filter((m) => m.timestamp !== newMessage.timestamp)
      .map((m) => ({
        content: m.content,
        role:
          (m as IMessage).sender instanceof User
            ? MessageRole.USER
            : MessageRole.ASSISTANT,
      }));

    messages.push(systemMessage);

    if (assistant.context) {
      const context = {
        role: MessageRole.USER,
        content: JSON.stringify(assistant?.context),
      };
      messages.push(context);
    }

    messages.push(...previousMessages);
    messages.push(userMessage);

    return { model: assistant.apiSpec.params.model, messages };
  }

  public static fromAPIResponse(response: any) {
    const responseMessages = response.choices.map((c) => ({
      sender: c.message.role,
      content: c.message.content,
    }));

    return responseMessages;
  }

  public static toStateAssistant(assistants, data: any): IAssistant[] {
    return assistants.map(({ assistant, scheme }) => {
      return {
        id: assistant.id,
        name: assistant.name,
        personalityPrompt: assistant.personalityPrompt ?? "",
        description: assistant.description ?? {},
        iconImage: assistant.iconImage,
        apiSpec: assistant.apiSpec ?? {},
        context: this.getContext(scheme, data),
      };
    });
  }

  public static getContext(scheme: string, data: any) {
    if (scheme) {
      const formattedScheme = scheme.replace(/\${(\w+)}/g, (match, key) => {
        return data[key] || match;
      });

      return formattedScheme;
    } else {
      return data;
    }
  }
}
