<ng-container *ngIf="isLoggedIn && chatVisible">
  <div
    *ngIf="{
      assistants: assistants$ | async,
      authUser: currentUser$ | async
    } as state"
    class="card text-center cardContainer resizable"
    [ngClass]="{
      'cardContainer-minimized': isChatMinimized,
      resizable: !isChatMinimized
    }"
    (mousedown)="startResize($event)"
  >
    <div class="card-header">
      <ng-container *ngIf="activeAssistant; else noActiveAssitant">
        <div>
          <img
            src="../../../assets/media/florenceLLM.png"
            alt="{{ activeAssistant.name }}"
            class="assistant-image"
          />

          {{ activeAssistant.name }}
        </div>
      </ng-container>
      <ng-template #noActiveAssitant>
        <div class="core-container">
          <div class="core-text">
            {{ "core.think" | translate | uppercase }}
          </div>
        </div></ng-template
      >
      <div class="buttons-container">
        <button mat-icon-button (click)="reset()" class="close-button">
          <i style="color: white" class="ri-arrow-go-back-line"></i>
        </button>

        <button mat-icon-button (click)="toggleChat()" class="close-button">
          <span style="color: white">_</span>
        </button>
        <button mat-icon-button (click)="closeChat()" class="close-button">
          <i style="color: white" class="ri-close-line"></i>
        </button>
      </div>
    </div>
    <div
      class="card-body"
      [ngClass]="{ loading: loading$ | async }"
      [hidden]="isChatMinimized"
    >
      <ng-container *ngIf="!activeAssistant; else chatWithAssistant">
        <ng-container *ngIf="loading$ | async; else assistantsLoaded">
          <div class="spinner"></div>
        </ng-container>
        <ng-template #assistantsLoaded>
          <div class="message-bubble">
            <ng-container
              *ngIf="state.assistants.length === 0; else assistantsAvailable"
            >
              <p class="message-received p-0">
                {{ "help.no_assistants_available" | translate }}
              </p>
            </ng-container>
            <ng-template #assistantsAvailable>
              <p class="message-received p-0">
                {{ "help.choose_chat_assistant" | translate }}
              </p>
            </ng-template>
            <ng-container *ngFor="let assistant of state.assistants">
              <div
                class="assistant-button"
                (click)="selectAssistant(assistant.id)"
              >
                <div class="assistant-info">
                  <img
                    src="../../../assets/media/florenceLLM.png"
                    alt="{{ assistant.name }}"
                    class="assistant-image"
                  />

                  <div class="assistant-text">
                    <span class="assistant-name">{{ assistant.name }}</span>

                    <span class="assistant-description">{{
                      getAssistantDescription(assistant.description)
                    }}</span>
                  </div>
                </div>
              </div>
            </ng-container>
          </div>
        </ng-template>
      </ng-container>
      <ng-template #chatWithAssistant>
        <ng-container *ngIf="currentContext === 'case'">
          <div class="message-received">
            {{ "help.case_assistant" | translate }}
          </div>
        </ng-container>
        <div
          *ngFor="let message of messages"
          [ngClass]="
            getSenderRole(message.sender) ? 'message-sent' : 'message-received'
          "
        >
          <span
            [ngClass]="
              getSenderRole(message.sender)
                ? 'triangle-sent'
                : 'triangle-received'
            "
            class="triangle"
          ></span>
          <div [innerHTML]="message.content | formattext"></div>
        </div>
      </ng-template>
      <div *ngIf="sending$ | async" class="loading-indicator-container">
        <div class="loading-indicator"></div>
        <div class="loading-indicator"></div>
        <div class="loading-indicator"></div>
      </div>
    </div>
    <form
      class="card-footer text-muted input-group"
      [hidden]="isChatMinimized"
      (ngSubmit)="sendMessage()"
    >
      <div class="input-container">
        <input
          type="text"
          class="form-control chat-input"
          placeholder="Type your message here"
          [(ngModel)]="newMessage"
          [ngModelOptions]="{ standalone: true }"
          [disabled]="!activeAssistant"
        />
        <button
          mat-icon-button
          class="btn btn-outline-primary"
          [disabled]="!activeAssistant"
        >
          <i class="ri-send-plane-2-fill"></i>
        </button>
      </div>
    </form>
  </div>
</ng-container>
