import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";

import { FormatTextPipe } from "./format-text.pipe";

@NgModule({
  imports: [CommonModule],
  declarations: [FormatTextPipe],
  exports: [FormatTextPipe],
})
export class FormatTextPipeModule {}
