import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formattext",
})
export class FormatTextPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) return value;

    // Line breaks
    let formattedText = value.replace(/\n/g, "<br>");

    formattedText = formattedText
      .replace(/### (.+)/g, "<h3>$1</h3>") // Headings
      .replace(/\*\*(.+?)\*\*/g, "<strong>$1</strong>"); // Bold

    return formattedText;
  }
}
